<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <form v-else class="form-box container pd-bt-100">
      <b-row class="no-gutters mb-3">
        <b-col>
          <div class="font-weight-bold ft-20 header-tablepage">
            Send Message Detail
          </div>
        </b-col>
        <b-col class="text-right">
          <b-button class="btn-download" @click.prevent="downloadTemplate">
            Download Template
          </b-button>
        </b-col>
      </b-row>
      <b-tabs>
        <b-tab title="Set Up Message" active>
          <!-- general information -->
          <div class="background-white">
            <div class="bg-tabs-custom w-100">
              <b-row class="m-0">
                <b-col class="text-left">
                  <b-button
                    @click.prevent="showCollapse(1)"
                    class="w-100 text-left btn-expand bg-tabs-custom"
                  >
                    <b-form-checkbox v-model="generalInformationSelect"
                      >General Information</b-form-checkbox
                    >
                  </b-button>
                </b-col>
                <b-col
                  class="text-right"
                  v-if="!showInformation && disableStatus === false"
                >
                  <b-button
                    class="btn-edit w-100 text-right"
                    @click.prevent="showInformation = true"
                    >Edit</b-button
                  >
                </b-col>
                <b-col
                  v-if="showInformation && disableStatus === false"
                  class="text-right"
                >
                  <b-button
                    class="btn-edit-general btn-line-right py-0"
                    @click.prevent="saveInformation"
                    >Save</b-button
                  >
                  <b-button
                    class="btn-edit-general py-0"
                    @click.prevent="showInformation = false"
                    >Cancel</b-button
                  >
                </b-col>
              </b-row>
            </div>
            <div v-if="showInformation" class="p-3">
              <GeneralPanel
                :disableStatus="disableStatus"
                :v="$v"
                :form="form"
                :campaignList="campaignList"
              />
            </div>
          </div>
          <!-- condition -->
          <div class="mt-1">
            <div class="bg-tabs-custom w-100">
              <b-row class="m-0">
                <b-col>
                  <b-button
                    @click.prevent="sideBarCondition"
                    class="w-100 text-left btn-expand bg-tabs-custom"
                  >
                    <b-form-checkbox v-model="conditionSelect" class="pointer"
                      >Conditions (Segmentation)</b-form-checkbox
                    >
                  </b-button>
                </b-col>
                <b-col class="text-right">
                  <b-button
                    class="btn-edit w-100 text-right"
                    v-if="!disableStatus"
                    @click="sideBarCondition"
                    >Edit</b-button
                  >
                </b-col>
              </b-row>
            </div>
            <div class="bg-white" v-if="conditionSettingValue">
              <div class="card card-body mb-0 black-color">
                <DisplayResultAutomation
                  :memberLevel="memberLevel"
                  :privilegeList="privilegeCodeList"
                  :settingValue="conditionSettingValue"
                  :form="form"
                  :display="3"
                />
              </div>
            </div>
          </div>
          <!-- action -->
          <div class="mt-1">
            <div class="bg-tabs-custom w-100">
              <b-row class="m-0">
                <b-col>
                  <b-button
                    @click.prevent="sideBarAction"
                    class="w-100 text-left btn-expand bg-tabs-custom"
                  >
                    <b-form-checkbox v-model="actionSelect"
                      >Privilege Use</b-form-checkbox
                    >
                  </b-button>
                </b-col>
                <b-col class="text-right">
                  <b-button
                    class="btn-edit w-100 text-right"
                    @click="sideBarAction"
                    >Edit</b-button
                  >
                </b-col>
              </b-row>
            </div>
            <div
              class="card card-body mb-0 black-color"
              v-if="actionSettingValue"
            >
              <DisplayResultAutomation
                :form="form"
                :display="4"
                :redeemCodeList="redeemCodeList"
                :settingValue="actionSettingValue"
              />
            </div>
          </div>
          <!-- communication -->
          <div class="mt-1">
            <b-button
              class="bg-tabs-custom w-100 btn btn-primary"
              type="button"
              data-toggle="collapse"
              data-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <b-row class="m-0 p-0 bg-tabs-custom">
                <b-col>
                  <b-form-checkbox
                    v-model="communicationSelect"
                    @click.native.prevent
                    >Message</b-form-checkbox
                  >
                </b-col>
              </b-row>
            </b-button>
            <div
              :class="
                id === '0' ? 'card collapse mb-0' : 'card collapse show mb-0'
              "
              id="collapseExample"
            >
              <b-row class="my-3 mx-2">
                <!-- SMS -->
                <b-col md="4">
                  <DisplayCommunication
                    :title="'Message'"
                    :edit="smsEdit"
                    :value="form.communication"
                    @sideBarOpen="sideBarSMS"
                    @deleteMessage="deleteMessage"
                    :idCommu="2"
                    :disableStatus="disableStatus"
                    @modalTestSend="modalTestSend"
                  />
                </b-col>
                <!-- Email -->
                <b-col md="4">
                  <DisplayCommunication
                    :title="'Email'"
                    :edit="emailEdit"
                    :value="form.communication"
                    @sideBarOpen="sideBarEmail"
                    @deleteMessage="deleteMessage"
                    :idCommu="1"
                    :disableStatus="disableStatus"
                    @modalTestSend="modalTestSend"
                  />
                </b-col>
                <!-- Line -->
                <b-col md="4">
                  <DisplayCommunicationLine
                    :title="'Line'"
                    :edit="lineEdit"
                    :value="form.communication"
                    @sideBarOpen="sideBarLine"
                    @deleteMessage="deleteMessage"
                    :disableStatus="disableStatus"
                  />
                </b-col>
              </b-row>
              <b-row class="mb-3 mx-2">
                <b-col>
                  <InputCheckBox
                    text="Send Messages On All Channels"
                    @change="(val) => (form.sent_all = val)"
                    :checked="1"
                    :unchecked="0"
                    :value="parseInt(form.sent_all)"
                  />
                </b-col>
              </b-row>
            </div>
          </div>
          <!-- time -->
          <div class="mt-1 background-white">
            <div class="bg-tabs-custom w-100">
              <b-row class="m-0">
                <b-col class="text-left">
                  <b-button
                    @click.prevent="showTimeMessage = true"
                    class="w-100 text-left btn-expand bg-tabs-custom"
                  >
                    <b-form-checkbox v-model="timeMessageSelect"
                      >Set Time</b-form-checkbox
                    >
                  </b-button>
                </b-col>
                <b-col
                  class="text-right"
                  v-if="!showTimeMessage && disableStatus === false"
                >
                  <b-button
                    class="btn-edit w-100 text-right"
                    @click="showTimeMessage = true"
                    v-if="!showTimeMessage && !disableStatus"
                    >Edit</b-button
                  >
                </b-col>
                <b-col
                  v-if="showTimeMessage && disableStatus === false"
                  class="text-right"
                >
                  <span>
                    <b-button
                      class="btn-edit-general btn-line-right py-0"
                      @click.prevent="saveTimeSendMessage"
                      >Save</b-button
                    >
                    <b-button
                      class="btn-edit-general py-0"
                      @click="showTimeMessage = false"
                      >Cancel</b-button
                    >
                  </span>
                </b-col>
              </b-row>
            </div>
            <div class="card card-body mb-0 black-color" v-if="showTimeMessage">
              <SendMessageTimePanel
                :disableStatus="disableStatus"
                :v="$v"
                :form="form"
                :selected="form.is_send"
                @setTime="(val) => (form.is_send = val)"
              />
            </div>
          </div>
          <div class="footer" v-show="showFooter()">
            <b-row>
              <b-col md="6">
                <b-button
                  class="btn-cancel-automation"
                  @click.prevent="closeAutomation"
                  >Cancel</b-button
                >
              </b-col>
              <b-col class="text-right" md="6">
                <!-- <b-button class="btn-template mr-2" @click.prevent="sendForm(1)"
                  >บันทึกฉบับร่าง</b-button
                > -->
                <b-button
                  class="btn-confirm-automation"
                  @click.prevent="sendForm(0)"
                  >Confirm Send Message</b-button
                >
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="Customer List" justified v-if="id !== '0'">
          <DisplayOverviewReport
            v-if="userData"
            :userData="userData"
            :filter="filter"
            :isBusy="isBusy"
            :id="id"
            :fields="fields"
            :showingTo="showingTo"
            :rows="rows"
            :type="'segmentation'"
            @searchUserRegister="searchUserRegister"
            @getDataInPagination="getDataInPagination"
          />
        </b-tab>
      </b-tabs>
      <SidebarConditionPanel
        ref="sidebarCondition"
        :selected="conditionSettingValue"
        :conditionList="conditionList"
        :memberLevel="memberLevel"
        :privilegeList="privilegeCodeList"
        :gender="genderList"
        :operatorList="operatorList"
        :form="form.condition"
        @settingCondition="conditionValue"
        :v="$v.form.condition"
        :formMain="form"
      />
      <SidebarSMSPanel
        ref="sidebarSMS"
        :form="form.communication"
        @settingSMS="communicationValue"
        :formMain="form"
        typeSMS="segment"
      />
      <SidebarEmailPanel
        ref="sidebarEmail"
        :form="form.communication"
        @settingEmail="communicationValue"
        :formMain="form"
        typeEmail="segment"
      />
      <SidebarLinePanel
        ref="sidebarLine"
        :form="form.communication"
        :v="$v.form.communication"
        :communicationList="communicationList"
        :messageType="messageType"
        :id="id"
        :dataImage="dataImage"
        @settingLine="communicationValue"
        :formMain="form"
        typeLine="segment"
      />
      <SidebarActionPanel
        ref="sidebarAction"
        :selected="actionSettingValue"
        @settingAction="actionValue"
        :dropdownAction="dropdownAction"
        :dropdownAction1="dropdownAction1"
        :redeemCodeList="redeemCodeList"
        :form="form.action"
        :v="$v.form.action"
      />
      <TestSendSmsEmail
        ref="modalTestSend"
        @testSendEmailSMS="testSendEmailSMS"
      />
    </form>
  </div>
</template>

<script>
import GeneralPanel from "@/components/automation/segmentation/GeneralPanel";
import SendMessageTimePanel from "@/components/automation/segmentation/SendMessageTimePanel";
import SidebarConditionPanel from "@/components/automation/component/SidebarConditionPanel";
import SidebarSMSPanel from "@/components/automation/component/SidebarSMSPanel";
import SidebarEmailPanel from "@/components/automation/component/SidebarEmailPanel";
import SidebarLinePanel from "@/components/automation/component/SidebarLinePanel";
import DisplayCommunication from "@/components/automation/communication/DisplayCommunication";
import DisplayCommunicationLine from "@/components/automation/communication/DisplayCommunicationLine";
import DisplayResultAutomation from "@/components/automation/component/DisplayResultAutomation";
import OtherLoading from "@/components/loading/OtherLoading";
import { required, requiredIf, url } from "vuelidate/lib/validators";
import DisplayOverviewReport from "@/components/automation/detail/DisplayOverviewReport";
import InputCheckBox from "@/components/inputs/InputCheckBox";
import SidebarActionPanel from "@/components/automation/component/SidebarActionPanel";
import TestSendSmsEmail from "@/components/automation/component/TestSendSmsEmail";
export default {
  components: {
    GeneralPanel,
    SidebarConditionPanel,
    SidebarSMSPanel,
    SidebarEmailPanel,
    SidebarLinePanel,
    OtherLoading,
    DisplayCommunication,
    DisplayCommunicationLine,
    SendMessageTimePanel,
    DisplayResultAutomation,
    DisplayOverviewReport,
    InputCheckBox,
    SidebarActionPanel,
    TestSendSmsEmail,
  },
  data() {
    return {
      eventList: [],
      conditionList: [],
      memberLevel: [],
      genderList: [],
      communicationList: [],
      campaignList: [],
      isLoading: true,
      messageType: [],
      form: {
        name: "",
        send_time: "",
        is_send: 0,
        is_draft: 0,
        sent_all: 0,
        segmentationType: null,
        campaign_id: null,
        condition: [],
        action: [],
        communication: [],
        import_condition: "",
      },
      id: this.$route.params.id,
      selectCommu: [],
      operatorList: [
        {
          id: 1,
          name: ">",
        },
        {
          id: 2,
          name: "<",
        },
        {
          id: 3,
          name: "=",
        },
        {
          id: 4,
          name: ">=",
        },
        {
          id: 5,
          name: "<=",
        },
      ],
      dataImage: [],
      showInformation: false,
      errorCampaign: false,
      generalInformationSelect: false,
      conditionSelect: false,
      timeMessageSelect: false,
      communicationSelect: false,
      showCondition: false,
      conditionSettingValue: 0,
      showTimeMessage: false,
      showCommunication: false,
      smsEdit: false,
      emailEdit: false,
      lineEdit: false,
      confirmMsg: "",
      currentTime: "",
      segmentationSelect: 0,
      disableStatus: false,
      userData: {},
      isBusy: true,
      filter: {
        search: "",
        page: 1,
        take: 10,
        channel: 0,
      },
      fields: [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "firstname",
          label: "Firstname",
        },
        {
          key: "lastname",
          label: "Lastname",
        },
        {
          key: "channel_sender",
          label: "Channel Sender",
        },
        {
          key: "sender_time",
          label: "Sender Time",
        },
        {
          key: "message_receiver",
          label: "Message Receiver",
        },
      ],
      items: [],
      rows: 0,
      showingTo: 0,
      privilegeCodeList: [],
      redeemCodeList: [],
      dropdownAction: [
        { id: 1, name: "Redeem Code" },
        { id: 2, name: "Point" },
      ],
      dropdownAction1: [{ id: 1, name: "Redeem Code" }],
      actionSettingValue: 0,
      actionSelect: false,
      formTestSMS: {
        text: "",
        email: "",
        telephone: "",
        type_communication: 0,
      },
    };
  },
  async created() {
    await this.getEvent();
    await this.getConditionList();
    await this.getMemberLevel();
    await this.getGender();
    await this.getCommunication();
    await this.getMessageType();
    await this.getCampagin();
    await this.getPrivilegeCode();
    await this.getRedeemCode();
    if (this.id !== "0") {
      this.getDetail();
      this.getUserRegister();
    }
  },
  validations: {
    form: {
      name: { required },
      send_time: {
        required: requiredIf(function (item) {
          return this.form.is_send === 2;
        }),
      },
      segmentationType: { required },
      campaign_id: {
        required: requiredIf(function (item) {
          return this.form.segmentationType === 2;
        }),
      },
      condition: {
        $each: {
          condition_type_id: {
            required: requiredIf(function (item) {
              return !item.condition_type_id;
            }),
          },
          operation_type_id: {
            required: requiredIf(function (item) {
              return !item.operation_type_id;
            }),
          },
          condition_value: {
            required: requiredIf(function (item) {
              return !item.condition_value;
            }),
          },
        },
      },
      action: {
        $each: {
          action_type_id: {
            required: requiredIf(function (item) {
              return !item.action_type_id;
            }),
          },
          action_value: {
            required: requiredIf(function (item) {
              return !item.action_value;
            }),
          },
        },
      },
      communication: {
        $each: {
          detail_list: {
            $each: {
              sort_order: { required },
              communication_value: {
                $each: {
                  value: {
                    required: requiredIf(function (item) {
                      return [7, 10, 11, 12].includes(
                        parseInt(item.message_type_id)
                      );
                    }),
                  },
                  image_url: {
                    required: requiredIf(function (item) {
                      return [7, 8, 12].includes(
                        parseInt(item.message_type_id)
                      );
                    }),
                  },
                },
              },
              format_list: {
                $each: {
                  option_name: {
                    required: requiredIf(function (item) {
                      return [7, 11].includes(parseInt(item.message_type_id));
                    }),
                  },
                  action_name: {
                    required: requiredIf(function (item) {
                      return [7, 11].includes(parseInt(item.message_type_id));
                    }),
                    url,
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  methods: {
    modalTestSend(val) {
      this.$refs.modalTestSend.show(val);
    },
    sideBarAction() {
      this.$refs.sidebarAction.show(true);
    },
    actionValue(val) {
      this.actionSelect = true;
      this.actionSettingValue = val;
    },
    deleteMessage(val) {
      if (val === 2) {
        const findIndexValue = this.form.communication.findIndex(
          (el) => el.communication_type_id === 2
        );
        this.form.communication.splice(findIndexValue, 1);
        this.smsEdit = false;
      } else if (val === 1) {
        const findIndexValue = this.form.communication.findIndex(
          (el) => el.communication_type_id === 1
        );
        this.form.communication.splice(findIndexValue, 1);
        this.emailEdit = false;
      } else if (val === 3) {
        const findIndexValue = this.form.communication.findIndex(
          (el) => el.communication_type_id === 3
        );
        this.form.communication.splice(findIndexValue, 1);
        this.lineEdit = false;
      }
    },
    conditionValue(val) {
      if (val) {
        this.conditionSelect = true;
        this.conditionSettingValue = val;
      }
    },
    async getRedeemCode() {
      await this.$store.dispatch("getRedeemCode", this.id);
      let data = this.$store.state.automation.redeemCodeList;
      if (data.result == 1) {
        this.redeemCodeList = data.detail;
      }
    },
    communicationValue(val) {
      if (val === 2) {
        this.showCommunication = true;
        this.communicationSelect = true;
        this.smsEdit = true;
      } else if (val === 1) {
        this.showCommunication = true;
        this.communicationSelect = true;
        this.emailEdit = true;
      } else if (val === 3) {
        this.showCommunication = true;
        this.communicationSelect = true;
        this.lineEdit = true;
      }
    },
    sideBarCondition() {
      this.$refs.sidebarCondition.show(true);
    },
    sideBarSMS() {
      const findTypeSMS = this.form.communication.find(
        (el) => el.communication_type_id === 2
      );
      if (!findTypeSMS) {
        this.form.communication.push({
          communication_type_id: 2,
          detail_list: [
            {
              communication_value: [
                {
                  value: "",
                  image_url: "",
                },
              ],
              format_list: [],
              message_type_id: 10,
              sort_order: 1,
            },
          ],
        });
      }
      this.$refs.sidebarSMS.show(true);
    },
    sideBarEmail() {
      const findTypeEmail = this.form.communication.find(
        (el) => el.communication_type_id === 1
      );
      if (!findTypeEmail) {
        this.form.communication.push({
          communication_type_id: 1,
          detail_list: [
            {
              communication_value: [
                {
                  value: "",
                  image_url: "",
                },
              ],
              format_list: [],
              message_type_id: 10,
              sort_order: 1,
            },
          ],
        });
      }
      this.$refs.sidebarEmail.show(true);
    },
    sideBarLine() {
      const findTypeLine = this.form.communication.find(
        (el) => el.communication_type_id === 3
      );
      if (!findTypeLine) {
        this.form.communication.push({
          communication_type_id: 3,
          detail_list: [
            {
              communication_value: [
                {
                  message_type_id: "10",
                  value: "",
                  image_url: "",
                },
              ],
              format_list: [],
              message_type_id: "10",
              sort_order: 1,
            },
          ],
        });
      }
      this.showCommunication = true;
      this.$refs.sidebarLine.show(true);
    },
    showCollapse(val) {
      if (val === 1) {
        this.showInformation = true;
      } else if (val === 2) {
        if (this.showCondition === false) {
          this.showCondition = true;
        } else {
          this.showCondition = false;
        }
      } else if (val === 3) {
        if (this.showTimeMessage === false) {
          this.showTimeMessage = true;
        }
      }
    },
    hideCollapse(val) {
      if (val === 1) {
        this.showInformation = false;
      } else if (val === 3) {
        if (this.showTimeMessage) {
          this.showTimeMessage = false;
        }
      }
    },
    async getDetail() {
      await this.$store.dispatch("getSegmentationById", this.id);
      const data = this.$store.state.automation.segmentationById;
      if (data.result === 1) {
        this.form = data.detail;
        this.generalInformationSelect = true;
        this.showInformation = true;
        this.conditionSelect = true;
        if (this.form.condition.length === 0) {
          this.conditionSettingValue = 2;
        } else {
          this.conditionSettingValue = 1;
        }
        if (this.form.action.length === 0) {
          this.actionSettingValue = 2;
        } else {
          this.actionSettingValue = 1;
        }
        if (this.form.campaign_id) {
          this.form.segmentationType = 2;
        } else {
          this.form.segmentationType = 1;
          this.form.campaign_id = null;
        }
        if (this.form.status_id !== 5) {
          this.disableStatus = true;
        }
        this.actionSelect = true;
        this.timeMessageSelect = true;
        this.communicationSelect = true;
        this.showTimeMessage = true;
        this.form.is_send = this.form.is_send === 0 ? 1 : this.form.is_send;
        if (this.form.communication) {
          const findEMail = this.form.communication.find(
            (el) => el.communication_type_id === 1
          );
          const findSMS = this.form.communication.find(
            (el) => el.communication_type_id === 2
          );
          const findLine = this.form.communication.find(
            (el) => el.communication_type_id === 3
          );
          if (findEMail) {
            this.emailEdit = true;
          }
          if (findSMS) {
            this.smsEdit = true;
          }
          if (findLine) {
            this.lineEdit = true;
          }
        }
        this.isLoading = false;
      }
    },
    getDataInPagination(value) {
      this.filter = value;
      this.getUserRegister();
    },
    async getUserRegister() {
      let reqData = {
        id: this.id,
        filter: this.filter,
      };
      await this.$store.dispatch("getUserSendMessage", reqData);
      const data = this.$store.state.automation.userSegmentation;
      if (data.result == 1) {
        this.userData = data.detail;
        this.items = data.detail.result;
        this.rows = data.detail.result_count;
        this.showingTo =
          this.items?.length >= 10 ? this.showingTo : this.items.length;
        this.isBusy = false;
      }
    },
    searchUserRegister() {
      this.getUserRegister();
    },
    async getPrivilegeCode() {
      await this.$store.dispatch("getPrivilegeCodeInAutomation");
      const data = this.$store.state.automation.privilegeCodeInAutomation;
      if (data.result == 1) {
        this.privilegeCodeList = data.detail;
      }
    },
    async getEvent() {
      await this.$store.dispatch("getEvent");
      let data = this.$store.state.automation.eventList;
      if (data.result == 1) {
        this.eventList = data.detail;
      }
    },
    async getConditionList() {
      await this.$store.dispatch("getCondition");
      let data = this.$store.state.automation.conditionList;
      if (data.result == 1) {
        this.conditionList = data.detail;
      }
    },
    async getMemberLevel() {
      await this.$store.dispatch("getMemberLevel");
      let data = this.$store.state.automation.memberLevel;
      if (data.result == 1) {
        this.memberLevel = data.detail;
      }
    },
    async getGender() {
      await this.$store.dispatch("getGender");
      let data = this.$store.state.automation.genderList;
      if (data.result == 1) {
        for (const gd of data.detail) {
          if (gd.id !== "0") {
            this.genderList.push({
              id: gd.id,
              name: gd.name,
              year: gd.year,
            });
          }
        }
      }
    },
    async getCommunication() {
      await this.$store.dispatch("getCommunication");
      let data = this.$store.state.automation.communicationList;
      if (data.result == 1) {
        this.communicationList = data.detail;
      }
    },
    async getMessageType() {
      await this.$store.dispatch("getMessageType");
      let data = this.$store.state.automation.messageType;
      if (data.result == 1) {
        this.messageType = data.detail;
        if (this.id === "0") {
          this.isLoading = false;
        }
      }
    },
    async getCampagin() {
      await this.$store.dispatch("getCampaign");
      let data = this.$store.state.automation.campaignList;
      if (data.result == 1) {
        this.campaignList = data.detail;
      }
    },
    setCurrentTime() {
      const currentdate = new Date();
      var hour = "";
      var minutes = "";
      var second = "";
      if (JSON.stringify(currentdate.getHours()).length < 2) {
        hour = "0" + currentdate.getHours();
      } else {
        hour = currentdate.getHours();
      }
      if (JSON.stringify(currentdate.getMinutes()).length < 2) {
        minutes = "0" + currentdate.getMinutes();
      } else {
        minutes = currentdate.getMinutes();
      }
      if (JSON.stringify(currentdate.getSeconds()).length < 2) {
        second = "0" + currentdate.getSeconds();
      } else {
        second = currentdate.getSeconds();
      }
      this.currentTime =
        currentdate.getFullYear() +
        "-" +
        (currentdate.getMonth() + 1) +
        "-" +
        currentdate.getDate() +
        "T" +
        hour +
        ":" +
        minutes +
        ":" +
        second +
        ".000+07:00";
    },
    submitConditionInForm() {
      // delete field in condition
      if (this.conditionSettingValue === 1) {
        for (const condition of this.form.condition) {
          if (condition.condition_type_id === 1) {
            delete condition.operation_type_name;
            delete condition.condition_type_name;
          }
          if (condition.condition_type_id === 2) {
            delete condition.operation_type_name;
            delete condition.condition_type_name;
          }
          if (
            condition.condition_type_id === 3 ||
            condition.condition_type_id === 4 ||
            condition.condition_type_id === 5
          ) {
            delete condition.operation_type_name;
            delete condition.condition_type_name;
          }
        }
      } else {
        this.form.condition = [];
      }
    },
    submitActionInForm() {
      // delete field in action
      if (this.actionSettingValue === 1) {
        for (const action of this.form.action) {
          if (action.action_type_id === 1) {
            delete action.action_type_name;
          }
          if (action.action_type_id === 2) {
            delete action.pointId;
            delete action.pointName;
            delete action.action_type_name;
          }
        }
      } else {
        this.form.action = [];
      }
    },
    submitCommunicationInForm() {
      // check communication
      const findCommuSMS = this.form.communication.filter(
        (el) => el.communication_type_id === 2
      );
      const findCommuEmail = this.form.communication.filter(
        (el) => el.communication_type_id === 1
      );
      const findCommuLine = this.form.communication.filter(
        (el) => el.communication_type_id === 3
      );
      if (findCommuSMS.length > 0) {
        const chkValueSMS =
          findCommuSMS[0].detail_list[0].communication_value[0].value;
        if (!chkValueSMS) {
          const findIndexSMS = this.form.communication.findIndex(
            (sms) => sms.communication_type_id === 2
          );
          this.form.communication.splice(findIndexSMS, 1);
        }
      }
      if (findCommuEmail.length > 0) {
        const chkValueEmail =
          findCommuEmail[0].detail_list[0].communication_value[0].value;
        if (!chkValueEmail) {
          const findIndexEmail = this.form.communication.findIndex(
            (email) => email.communication_type_id === 1
          );
          this.form.communication.splice(findIndexEmail, 1);
        }
      }
      if (findCommuLine.length > 0) {
        if (findCommuLine[0].detail_list.length > 0) {
          if (parseInt(findCommuLine[0].detail_list[0].message_type_id) === 8) {
            const chkValueImg =
              findCommuLine[0].detail_list[0].communication_value[0].image_url;
            if (!chkValueImg) {
              const findIndexLineImg = this.form.communication.findIndex(
                (line) => line.communication_type_id === 3
              );
              this.form.communication.splice(findIndexLineImg, 1);
            }
          } else {
            const chkValueLine =
              findCommuLine[0].detail_list[0].communication_value[0].value;
            if (!chkValueLine) {
              const findIndexLine = this.form.communication.findIndex(
                (linePosition) => linePosition.communication_type_id === 3
              );
              this.form.communication.splice(findIndexLine, 1);
            } else {
              // check commu value empty
              for (let i = 0; i <= findCommuLine[0].detail_list.length; i++) {
                let findIndexLine = findCommuLine[0].detail_list.findIndex(
                  (el) =>
                    parseInt(el.message_type_id === 8)
                      ? el.communication_value[0].image_url === ""
                      : el.communication_value[0].value === "" &&
                        el.communication_value[0].image_url === ""
                );
                if (findIndexLine !== -1) {
                  findCommuLine[0].detail_list.splice(findIndexLine, 1);
                }
              }
            }
          }
        } else {
          const findIndexLinePosition = this.form.communication.findIndex(
            (el) => el.communication_type_id === 3
          );
          this.form.communication.splice(findIndexLinePosition, 1);
        }
      }
    },
    async sendForm(draft) {
      await this.setCurrentTime();
      await this.submitConditionInForm();
      await this.submitActionInForm();
      await this.submitCommunicationInForm();
      this.form.is_draft = draft;
      this.form.is_send = this.form.is_send === 0 ? 1 : this.form.is_send;
      this.form.send_time =
        this.form.is_send === 2 ? this.form.send_time : this.currentTime;
      const findAction = this.form.action.some(
        (el) => el.action_value === null
      );
      if (findAction) this.form.action = [];
      // 1 = draft
      if (draft === 1) {
        this.isDraftAutomation();
      } else {
        if (!this.generalInformationSelect) {
          this.$swal("Please save general information.", {
            icon: "warning",
          });
          return;
        } else {
          this.$v.form.name.$touch();
          this.$v.form.segmentationType.$touch();
          this.$v.form.campaign_id.$touch();
          if (
            this.$v.form.name.$error ||
            this.$v.form.campaign_id.$error ||
            this.$v.form.segmentationType.$error
          ) {
            this.$swal("Please save general information.", {
              icon: "warning",
            });
            this.generalInformationSelect = false;
            return;
          }
        }
        if (!this.conditionSelect) {
          this.$swal("Please set the terms of access rights.", {
            icon: "warning",
          });
          return;
        }
        if (!this.actionSettingValue) {
          this.$swal("กรุณากำหนดการใช้สิทธิพิเศษ", {
            icon: "warning",
          });
          return;
        }
        if (!this.communicationSelect) {
          this.$swal("กรุณาระบุข้อความ", {
            icon: "warning",
          });
          return;
        } else {
          const findSMS = this.form.communication.find(
            (el) => el.communication_type_id === 2
          );
          const findEmail = this.form.communication.find(
            (e) => e.communication_type_id === 1
          );
          const findLine = this.form.communication.find(
            (l) => l.communication_type_id === 3
          );
          if (!findSMS && !findEmail && !findLine) {
            this.$swal("Please add at least one channel to access information.", {
              icon: "warning",
            });
            return;
          }
        }
        if (!this.timeMessageSelect) {
          this.$swal("Please note the time of sending the message.", {
            icon: "warning",
          });
          return;
        } else {
          if (this.form.is_send === 0) {
            this.$swal("กรุณาตั้งเวลาการส่งข้อความ", {
              icon: "warning",
            });
            return;
          }
        }
        this.isDraftAutomation();
      }
    },
    async isDraftAutomation() {
      this.isLoading = true;
      this.form.campaign_id =
        this.form.segmentationType === 2 ? this.form.campaign_id : 0;
      delete this.form.segmentationType;
      if (this.id === "0") {
        await this.$store.dispatch("createSegmentation", this.form);
        const data = this.$store.state.automation.respCreateSegmentation;
        if (data.result === 1) {
          this.$swal("Create Success!", {
            icon: "success",
          }).then(function () {
            window.location.href = "/automation/sendmessage";
          });
        } else {
          this.isLoading = false;
          this.$swal(data.message, {
            icon: "warning",
          });
        }
      } else {
        let reqData = {
          id: this.id,
          form: this.form,
        };
        delete this.form.id;
        await this.$store.dispatch("updateSegmentation", reqData);
        const dataUpdate = this.$store.state.automation.respUpdateSegmentation;
        if (dataUpdate.result === 1) {
          this.$swal("Update Success!", {
            icon: "success",
          }).then(function () {
            window.location.href = "/automation/sendmessage";
          });
        } else {
          this.isLoading = false;
          this.$swal(dataUpdate.message, {
            icon: "warning",
          });
        }
      }
    },
    closeAutomation() {
      window.location.href = "/automation/sendmessage";
    },
    saveInformation() {
      this.$v.form.name.$touch();
      this.$v.form.segmentationType.$touch();
      this.$v.form.campaign_id.$touch();
      if (
        this.$v.form.name.$error ||
        this.$v.form.campaign_id.$error ||
        this.$v.form.segmentationType.$error
      ) {
        this.generalInformationSelect = false;
        return;
      } else {
        this.generalInformationSelect = true;
      }
    },
    saveTimeSendMessage() {
      if (this.form.is_send === 0) {
        this.$swal("กรุณาตั้งเวลา", {
          icon: "warning",
        });
        return;
      } else if (this.form.is_send === 2) {
        this.$v.form.send_time.$touch();
        if (this.$v.form.send_time.$error) {
          return;
        }
      }
      this.timeMessageSelect = true;
    },
    showFooter() {
      if (this.id === "0") {
        return true;
      } else {
        if (this.form.status_id === 5) {
          return true;
        } else {
          return false;
        }
      }
    },
    async testSendEmailSMS(val) {
      if (val.type === 2) {
        this.formTestSMS.telephone = val.data;
        this.formTestSMS.email = "";
        this.formTestSMS.type_communication = 2;
        let SMS = this.form.communication.find(
          (el) => el.communication_type_id === 2
        );
        this.formTestSMS.text = SMS.detail_list[0].communication_value[0].value;
      } else if (val.type === 1) {
        this.formTestSMS.email = val.data;
        this.formTestSMS.telephone = "";
        this.formTestSMS.type_communication = 1;
        let SMS = this.form.communication.find(
          (el) => el.communication_type_id === 1
        );
        this.formTestSMS.text = SMS.detail_list[0].communication_value[0].value;
      } else if (val.type === 4) {
        this.formTestSMS.telephone = val.data;
        this.formTestSMS.email = "";
        this.formTestSMS.type_communication = 4;
        let SMS = this.form.communication.find(
          (el) => el.communication_type_id === 4
        );
        this.formTestSMS.text = SMS.detail_list[0].communication_value[0].value;
      }
      await this.actionTestSendEmailSMS();
    },
    async actionTestSendEmailSMS() {
      await this.$store.dispatch("testSendSMSOrEmail", this.formTestSMS);
      const data = this.$store.state.automation.stateTestSendSMSOrEmail;
      this.$swal("Success", {
        icon: "success",
      });
    },
    downloadTemplate() {
      window.open(
        `${process.env.VUE_APP_API_TEMPLATE}/template/template_for_send_message_customer_upload.xlsx`,
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .nav-tabs .nav-link:not(.active):hover {
  background-color: transparent;
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}
.bg-tabs-custom {
  text-align: left;
  border: none;
  background: var(--secondary-color);
  border-radius: 0px;
  color: var(--font-color);
  font-weight: 600;
  padding: 10px 0;
}
.ft-20 {
  font-size: 20px;
}
.btn-edit {
  color: var(--primary-color);
  border: none;
  background-color: transparent;
  font-weight: 600;
  padding: 0 !important;
}
::v-deep .header-tablepage {
  color: #092d53;
}
.btn-edit-general {
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: var(--primary-color);
  border-radius: 0px;
}
.btn-line-right {
  border-right: 1px solid var(--primary-color);
}
.footer {
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 256px;
  right: 0;
  padding: 10px;
  background: #fff;
}
.btn-template {
  color: var(--primary-color);
  border-color: var(--primary-color);
  background-color: transparent;
  border-radius: 0px;
  width: 30%;
}
.btn-confirm-automation {
  color: #fff;
  border-radius: 0px;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  width: 30%;
}
.btn-cancel-automation {
  color: #fff;
  background-color: #39424e;
  border-radius: 0px;
  width: 30%;
  border: 1px solid #39424e;
}
.btn-delete-automation {
  background-color: transparent;
  border: 1px solid #39424e;
  border-radius: 0px;
  width: 30%;
  color: #39424e;
}
.add-new {
  text-decoration: underline;
}
.font-w-600 {
  font-weight: 600;
}
.black-color {
  color: #000;
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.text-edit-communication {
  color: gray;
  font-weight: 100;
}
.background-white {
  background-color: #fff;
}
.pd-bt-100 {
  padding-bottom: 100px;
}
.btn-expand {
  padding: 0;
  background-color: transparent;
  border: none;
}
::v-deep .btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: transparent;
  border-color: transparent;
}
.btn-download {
  background-color: #fff;
  color: var(--primary-color);
}
</style>
